/**
 * @description: 服务器管理页面接口列表
 */

import request from './request'

// 健康检测
export function health_check (data) {
  return request({
    url: '/server_api/health_check',
    method: 'post',
    data
  })
}

// 生成证书
export function generate_cert (data) {
  return request({
    url: '/server_api/generate_cert',
    method: 'post',
    data
  })
}

// 发布
export function send_server (data) {
  return request({
    url: '/server_api/send_server',
    method: 'post',
    data
  })
}

/**
 * @description: 删除服务器
 * @param: {
 *  server_ids: Array, IP地址数组
 * }
 */

export function delete_server (data) {
  return request({
    url: '/server_api/server',
    method: 'delete',
    data
  })
}

/**
 * @description: 更新服务器配置
 * @param: {
 *  server_id: Number, 服务器ID
 *  server_ip: String, 服务器IP
 *  username: String,
 *  password: String,
 *  server_types: Array, 服务类型
 * }
 */

export function update_server (data) {
  return request({
    url: '/server_api/server',
    method: 'put',
    data
  })
}

/**
 * @description: 新增服务器配置
 * @param: {
 *  server_ip: String, 服务器IP
 *  username: String,
 *  password: String,
 *  server_types: Array, 服务类型
 * }
 */

export function add_server (data) {
  return request({
    url: '/server_api/server',
    method: 'post',
    data
  })
}

/**
 * @description: 查询服务器列表
 * @param: {
 *  page_num: Number,
 *  page_size: Number,
 *  order_by: String,
 *  sort: Number,
 *  query_condition: Object, // 查询条件
 * }
 */

export function get_server_list (data) {
  return request({
    url: '/server_api/servers',
    method: 'post',
    data
  })
}

/**
 * @description: 获取服务类型
 */
export function get_server_types () {
  return request({
    url: '/server_api/server_types',
    method: 'get'
  })
}

// ims日志上传
export function ims_log (data) {
  return request({
    url: '/log_api/ims_log',
    method: 'post',
    data
  })
}
// 端口检查
export function port_check (data) {
  return request({
    url: '/server_api/port_check',
    method: 'post',
    data
  })
}

// 查询服务器详情
export function get_server_detail (id) {
  return request({
    url: `/server_api/server/${id}`,
    method: 'get'
  })
}
// 查询区域详情
export function get_region_list (id) {
  return request({
    url: '/server_api/scheduling_regions',
    method: 'get'
  })
}

// 添加区域
export function add_scheduling_regions (data) {
  return request({
    url: '/server_api/scheduling_regions',
    method: 'post',
    data
  })
}
// 修改区域
export function update_scheduling_regions (data) {
  return request({
    url: '/server_api/scheduling_regions',
    method: 'put',
    data
  })
}
// 删除区域
export function del_scheduling_regions (data) {
  return request({
    url: '/server_api/scheduling_regions',
    method: 'delete',
    data
  })
}

// 下发区域
export function send_scheduling_regions (data) {
  return request({
    url: '/server_api/send_scheduling_region',
    method: 'post',
    data
  })
}

// IMS升级
export function update_ims_server (data) {
  return request({
    url: '/server_api/update_ims_server',
    method: 'post',
    data
  })
}

// ims推流监测
export function ims_stream_list (params) {
  return request({
    url: '/server_api/ims_stream_list',
    method: 'get',
    params
  })
}

// 下载license文件
export function activate_file (params) {
  return request({
    url: '/server_api/activate_file',
    method: 'get',
    responseType: 'blob',
    params
  })
}

// 下载配置证书
export function download_cert (params) {
  return request({
    url: '/server_api/download_cert',
    method: 'get',
    responseType: 'blob',
    params
  })
}

<template>
  <div>
    <div class="operate">
      <el-page-header
        :content="$t(title)"
        @back="goBack"
      />
      <div class="tool">
        <div>
          <slot name="header" />
        </div>
      </div>
    </div>
    <slot />
    <div class="page">
      <slot name="page" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang='scss' scoped>
.operate {
  position: sticky;
  background: #fff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
  border-bottom: 1px solid #cecece;
  top: 0;
  left: 0;
  z-index: 100;
}
.tool {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.page {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
</style>
